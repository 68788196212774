<template>
  <div class="root">
    <b-card>
      <b-card-header>
        <b-card-title>
          <h1>Edycja usługi: {{ offer.title }}</h1>
        </b-card-title>
      </b-card-header>
    </b-card>
    <b-card>
      <b-card-header>
        <b-card-title>
          <h2>informacje:</h2>
        </b-card-title>
      </b-card-header>
      <b-card-text>
        <div class="form-group">
          <input
            id="title"
            v-model="offer.title"
            placeholder="Nazwa usługi"
            type="text"
            class="form-control"
          >
        </div>
        <div class="form-group">
          <input
            id="title"
            v-model="offer.address"
            type="text"
            placeholder="Adres"
            class="form-control"
          >
        </div>
        <div class="form-group">
          <input
            id="title"
            v-model="offer.phone"
            type="text"
            placeholder="Telefon kontaktowy"
            class="form-control"
          >
        </div>
        <div class="form-group">
          <input
            id="title"
            v-model="offer.city"
            placeholder="Miasto"
            type="text"
            class="form-control"
          >
        </div>
      </b-card-text>
    </b-card>
    <b-card>
      <b-card-header>
        <b-card-title>
          <h2>Opis usługi:</h2>
        </b-card-title>
      </b-card-header>

      <editor
        v-model="offer.description"
        api-key="b8rjrbaxn62c6iwmxh1tg94bjzp309azf3n243jxljv1ndwn"
        :init="{
          height: 500,
          menubar: false,
          plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount'
          ],
          toolbar:
            'undo redo | formatselect | bold italic backcolor | \
                alignleft aligncenter alignright alignjustify | \
                bullist numlist outdent indent | removeformat | help'
        }"
      />
    </b-card>

    <b-card>
      <h2>Obraz</h2>
      <b-form-file
        id="file"
        type="file"
        name="file"
        placeholder="Wybierz obraz"
      />
    </b-card>

    <b-card>
      <b-card-header>
        <b-card-title>
          <h2>Dodatkowe informacje: </h2>
        </b-card-title>
      </b-card-header>
      <b-card-text>
        <div class="form-group">
          <input
            id="title"
            v-model="offer.tags"
            type="text"
            placeholder="Tagi (Oddziel tagi oferty po przecinku)"
            class="form-control"
          >
        </div>
      </b-card-text>
      <div class="form-group">
        <input
          id="title"
          v-model="offer.price"
          type="number"
          placeholder="Cena (zł)"
          class="form-control"
        >
      </div>

      <div class="form-group">
        <input
          id="estaminated_time"
          v-model="offer.estaminated_time"
          type="number"
          placeholder="Czas wykonania (h)"
          class="form-control"
        >
      </div>

      <div class="form-group">
        <input
          v-model="offer.notification_message"
          type="text"
          class="form-control"
          placeholder="Zawartość wiadomości SMS"
        >
      </div>

      <div class="form-group">
        <label for="estaminated_time">Czas wysłania sms</label>
        <select
          id="estaminated_time"
          v-model="offer.notification_hours_before"
          class="form-control"
        >
          <option>12</option>
          <option>24</option>
          <option>48</option>
        </select>
      </div>

    </b-card>
    <b-card>
      <button
        class="btn btn-primary dodaj"
        @click="process()"
      >
        Edytuj usługę
      </button>
    </b-card>
  </div>
</template>

<script>
import axios from 'axios'
import swal from 'sweetalert2'
import {
  BCard, BFormFile,
} from 'bootstrap-vue'
import Editor from '@tinymce/tinymce-vue'

export default {
  components: {
    BCard,
    BFormFile,
    Editor,
  },
  data() {
    return {
      offer: {},
    }
  },
  mounted() {
    document.title = `Usługa | ${this.$route.params.id} - Pickmode`

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    }
    axios.get(`${process.env.VUE_APP_API_URL}Offer/${this.$route.params.id}`, config)
      .then(response => {
        this.offer = response.data.offer
        this.offer.tags = this.offer.tags.map(tag => tag.name).join(', ')
      })
      .catch(error => {
        if (error.response.status === 404 || error.response.status === 401) {
          this.$router.push('/error-404')
        }
      })
  },
  methods: {
    process() {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }
      axios.put(`${process.env.VUE_APP_API_URL}Offer/${this.$route.params.id}`, this.offer, config)
        .then(() => {
          swal.fire({
            icon: 'success',
            title: 'Sukces!',
            text: 'Usługa została edytowana',
            type: 'success',
            confirmButtonText: 'Ok',
          })
        })
        .catch(() => {
          swal.fire({
            title: 'Błąd!',
            text: 'Nie udało się dodać usługi',
            type: 'error',
            confirmButtonText: 'Ok',
          })
        })
    },
  },
}
</script>
